@font-face {
  font-family: "DisplayProMedium";
  src: local("DisplayProMedium"),
    url("./assets/fonts/NeueHaasDisplay/NeueHaasDisplay-Mediu.ttf")
      format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "NeueMachinaMedium";
  src: local("NeueMachinaMedium"),
    url("./assets/fonts/NeueMachina/NeueMachina-Medium.ttf") 
      format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "DisplayProRoman";
  src: local("DisplayProRoman"),
    url("./assets/fonts/NeueHaasDisplay/NeueHaasDisplay-Roman.ttf")
      format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "NeueMontrealBold";
  src: local("NeueMontrealBold"),
    url("./assets/fonts/NeueMontreal/NeueMontreal-Bold.otf") format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "VanCondensedRegular";
  src: local("VanCondensedRegular"),
    url("./assets/fonts/VanCondensed/VanCondensedPro-Regular.ttf")
      format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "NeueMontrealRegular";
  src: local("NeueMontrealRegular"),
    url("./assets/fonts/NeueMontreal/NeueMontreal-Regular.otf")
      format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
    url("./assets/fonts/Inter/Inter-Regular.ttf")
      format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
    url("./assets/fonts/Inter/Inter-Medium.ttf")
      format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "InterSemiBold";
  src: local("InterSemiBold"),
    url("./assets/fonts/Inter/Inter-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./assets/fonts/Inter/Inter-Bold.ttf")
      format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "SplineSansMono";
  src: local("SplineSansMono"),
    url("./assets/fonts/SplineSansMono/SplineSansMono-Medium.ttf")
      format("truetype");
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Poppins", "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #110f13;
  scroll-behavior: smooth;
}
.groteskMedium {
  font-family: "DisplayProMedium";
}
.groteskRoman {
  font-family: "DisplayProRoman";
}
.neueMachinaMedium {
  font-family: "NeueMachinaMedium";
}
.interFont {
  font-family: "InterRegular";
}
.splineSansMono {
  font-family: "SplineSansMono";
}

div {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
.active {
  padding: 1.5px;
  background: linear-gradient(180deg, #2888be, #34b2a7);
  background-clip: padding-box, border-box;
  box-decoration-break: clone;
  position: relative;
  border-radius: 3px;
  margin-right: 8px;
}
.active div {
  padding: 8px 10px;
}
.footerLink.active {
  padding: 0;
  background: inherit;
  border: none;
  margin-right: 8px;
  padding: 0 0 20px 0 !important;
  &:hover {
    transition: none;
    background: transparent;
  }
}
.headerLink.active {
  padding: 0;
  background: inherit;
  margin-right: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.activeSideLink {
  background: linear-gradient(90deg, #2888be 0%, #34b2a7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.center {
  margin: 25% auto;
}
